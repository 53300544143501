.boxesContainer {
  display: flex !important;
  margin-bottom: 40px;
}

.homeText {
  margin: 34px 0 41px 0 !important;
  font-weight: bold !important;
}
@media (max-width: 767px) {
    .homeText {
      display: none
    };
    .boxesContainer{
        display: block !important;
        margin-top: 10px;
    }
  }
  
@media (min-width: 768px) {
  .boxesContainer {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .homeText {
    width: 50%;
    margin: 60px 0 !important;
  }
}

@media (min-width: 1040px) {
  .boxesContainer {
    justify-content: space-between;
  }
}

@media (min-width: 1280px) {
  .boxesContainer {
    flex-wrap: nowrap;
  }
}
