@import "../../colors/colors.scss";
.hero {
    width: 100%;
    height: 474px;
    background: #ffffff url(../../assets/hero-image.jpg) no-repeat
        center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 79px;
    padding: 10px 0;
}

.hero__header {
    font-size: 36px !important;
    line-height: 42px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    margin: 10px 0;
}

.hero__box {
    max-width: 280px;
    background-color: $brand-home-page-background;
    padding: 15px 9px 19px 15px;
    position: absolute;
    bottom: -240px;
    right: 0;
    display: flex;
    flex-direction: column;
}

.box__header {
    font-size: 21px !important;
    font-weight: 400;
    color: #fff;
    margin: 0 0 18px 0;
}

.box__input {
    color: $brand-color !important;
    background-color: #fff;
    margin-bottom: 14px;
    height: 36px !important;

    &::placeholder {
        color: grey !important;
    }
}

.box__additionalInfo {
    display: none;
}
@media (max-width: 767px) {
    .hero {
      margin-bottom: 0;
    }
    .hero__box {
      display: block;
      position: static;
      max-width: 100%;
      width: 100%;
      bottom: -230px;
      text-align: center;
      padding: 20px 10px;
    }
  }
@media (min-width: 768px) {
    .box__additionalInfo {
        display: initial;
        padding-top: 18px;
        border-top: 1px solid #fff;
        margin: 0 0 26px 0 !important;
        color: #fff !important;
    }

    .hero__box {
        max-width: 280px;
        bottom: -145px;
    }

    .hero {
        justify-content: flex-start;
        align-items: flex-end;
        margin-bottom: 60px;
        padding-bottom: 65px;
    }

    .hero__header {
        text-align: left;
        font-size: 48px !important;
    }
}

@media (min-width: 1280px) {
    .hero__box {
        min-width: 250px !important;
        max-width: 380px;
        padding: 23px 16px 32px 30px;

    }

    .hero__header {
        font-size: 90px !important;
    }

    .hero {
        height: 700px;
    }

    .box__header {
        font-size: 24px !important;
    }
}
